.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Navbar{
  background: white;
  z-index: 1000;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.091)
}
.navbar-sticky {
  position: sticky; top: 0
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.bg-grey{
  background-color: #e8e8e8
}

.bg-blue{
  background-color: #112b47
}

.form-control-dark{
  background: rgb(255 255 255 / 6%) !important;
}



/* --------------------------- */



.Header{
  background: linear-gradient(90deg, rgb(255 255 255) 0%, rgba(183,227,255,1) 100%)
}






.technologies-logo{
  filter: grayscale(1);
    opacity: .8;
    padding: 15px
}